

// Pagina pagamento fallito

// Base per lo sfondo
@mixin basic-generic-error-background-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.generic-error-background{
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
        // .generic-error-background{
        //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
        //     background-position: top left;
        //     position: relative;
        //     -webkit-background-size: auto;
        //     -moz-background-size: auto;
        //     -o-background-size: auto;
        //     background-size: auto;
        // }


        #generic_error_panel .card{
            margin-top: ($spacer * 1) !important;
            margin-bottom: ($spacer * 1) !important;
        }

}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .generic-error-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-generic-error-background-background;
    // }



    #generic_error_panel .card{
        margin-top: ($spacer * 3) !important;
        margin-bottom: ($spacer * 3) !important;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .generic-error-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-generic-error-background-background;
    // }



    #generic_error_panel .card{
        margin-top: ($spacer * 5) !important;
        margin-bottom: ($spacer * 5) !important;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }

}









