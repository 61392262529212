// PAGINA REGISTRAZIONE ACCOUNT

// Base per lo sfondo
@mixin basic-register-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


.register-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .register-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     @include basic-register-background;
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_register {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .register-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-register-background;
    // }

    #form_register {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_register .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_register .card {
        width: 620px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .register-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-register-background;
    // }

    #form_register {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_register .card {
        width: 620px;
    }

}

#form_register .invalid-feedback,
#register_form .valid-feedback {
    text-align: left;
}

.pdf-link {
    text-decoration: underline;
}

