// barra impersonate sotto menu principale
.impersonate-bar {
    background-color: rgba(219, 6, 6, 0.7);
    color:white;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem;
}
