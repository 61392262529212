

// Toglie il round cornere alla select
.form-control, .custom-select{
    border-radius: 0;
}

// Cambia la grandezza dei campi flex
.form_flex, .form_flex .d-flex{
    width:100%;
}

/*
.nam_required{
    background-color: #ededed;
}
*/

.alert_message_rl {
    white-space: pre-line;
}

// PECSW-16180
// Navigazione principale con logo

.navbar.top-logo-navbar,
.navbar.bottom-navbar{
    background-color: $top-navigation-bg;
}

// override stili di base
.btn-primary,
.bg-dark,
.landing_product_button .btn-primary {
    background-color: $primary;
    color:$white;
    text-decoration:none;
}

.card-body a {
    color: $teal;
    text-decoration: underline;
    font-weight: 500;
}

.card-body a.btn-primary,
.card-body a.btn-secondary {
    color: $white;
    text-decoration: none;
}


.card-body {
    button.btn-red,
    a.btn-red {
        background-color: $red-color !important;
        border-color: $red-color !important;

        &:hover {
            background-color: $red-color-bg-hover !important;
            border-color: $red-color-border-hover !important;
        }
    }
}



.friend_button,
.friend_button:hover {
    background-color: $white;
    border-color: $teal;
    color: $teal;
    font-weight: bold;
}

#navbarDropdown {
    text-transform: lowercase
}

/* Backoffice */
#navbarNav {
    .nav-item.dropdown a {
        text-transform:initial;
    }
}

@include media-breakpoint-only(xs) {
    #navbarDropdown {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
    }
}

// -- TABS
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: $seasalt;
    border-bottom-width: 0;
}

// -- Sfondi generici da utilizzare come classi specifiche, non disponibili in BS
.bg-light-yellow {
    background-color: $lightYellow;
}
.bg-seasalt {
    background-color: $seasalt;
}


// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

    .ownerform-background .card ,
    .recap-owner-background .card {
        width:$card-width-lg;
   }

}




