// SCELTA EMAIL

// Base per lo sfondo
@mixin basic-choose-email-bg {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


.choose_email_submit_container {
    @extend .mt-4;
    margin: auto;
}

.fix_domain_select {
    width: inherit !important;
}

// gestisco la visualizzazione della doppia descrizione e del doppio prezzo
#form_choose_email {
    .home_landing_page_view {
        display:none;
    }
}


.choose-email-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

//  Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .choose-email-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_choose_email {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .choose-email-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-choose-email-bg;
    // }

    #form_choose_email {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_choose_email .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .choose-email-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-choose-email-bg;
    // }

    #form_choose_email {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_choose_email .card {
        width: 600px;
    }

}


// Cambio colore placeholder per mostrare il messaggio di errore
.form-message-error input::placeholder {
    color: red;
    opacity: 1;
    /* Firefox */
}

.form-message-error input::-webkit-input-placeholder {
    color: red;
}

.form-message-error input:-ms-input-placeholder {
    color: red;
}

.form-message-error input::-ms-input-placeholder {
    color: red;
}

// Carattere @
.at_value {
    background-color: transparent;
    border: none;
}

// Popover
body>.popover>.arrow,
body>.popover>.arrow:after {
    border-top-color: #e3342f;
}

// Switch selezione servizi
.custom-control-input:checked~.custom-control-label:before {
    background-color: green;
}
.custom-switch .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: green;
}
.custom-switch .custom-control-input:disabled:checked~.custom-control-label:after {
    background-color:whitesmoke ;
}

// Riorganizzazione testi
.landing_product_price_regular {
    .lead {
        font-size: 1.25rem;
        font-weight: normal;
    }
}

.landing_product_price_renewal {
    .lead {
        font-weight: normal;
    }
}

