

// PAGINA FORMULARIO CREAZIONE PEC

// Base per lo sfondo
@mixin basic-createpec-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: auto;
    -moz-background-size: auto;
    -o-background-size: auto;
    background-size: auto;
    background-repeat: no-repeat;
}


// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    .createpec-background{
        background-image: url(../images/create_pec/bg_createpec_sm.jpg);
        @include basic-createpec-background;
    }

    #form_createpec{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;        
    }     
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    .createpec-background{
        background-image: url(../images/create_pec/bg_createpec_md.jpg);
        @include basic-createpec-background;
    }

    #form_createpec{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;        
    }  
    
    #form_createpec .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;  
    }    

    #form_createpec .card{
        width: 600px;
    }    
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .createpec-background{
        background-image: url(../images/create_pec/bg_createpec_lg.jpg);
        @include basic-createpec-background;
    }
 
    #form_createpec{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;        
    } 

    #form_createpec .card{
        width: 700px;
    }

}



#form_createpec .invalid-feedback, #createpec_form .valid-feedback{
    text-align: left;
}









