

// PAGINA FORMULARIO CREAZIONE PEC

// Base per lo sfondo
@mixin basic-billingform-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.billingform-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .billingform-background{
    //     background-image: url(../images/bg_billing/bg_billing_sm.jpg);
    //     @include basic-billingform-background;
    // }

    #form_billing{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .billingform-background{
    //     background-image: url(../images/bg_billing/bg_billing_md.jpg);
    //     @include basic-billingform-background;
    // }

    #form_billing{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_billing .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_billing .card{
        width: 600px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .billingform-background{
    //     background-image: url(../images/bg_billing/bg_billing_lg.jpg);
    //     @include basic-billingform-background;
    // }

    #form_billing{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_billing .card{
        width: 700px;
    }

}



#form_billingform .invalid-feedback, #billingform_form .valid-feedback{
    text-align: left;
}









