

// PAGINA DI LOGIN

// Base per lo sfondo
@mixin select-buyer-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    .select-buyer-background{
        background-image: url(../images/bg_billing/bg_billing_sm.jpg);
        @include basic-login-background;
    }

    #form_select_buyer, #form_select_buyer_blade, #form_select_buyer_async{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    .select-buyer-background{
        background-image: url(../images/bg_billing/bg_billing_md.jpg);
        @include basic-login-background;
    }

    #form_select_buyer, #form_select_buyer_blade, #form_select_buyer_async{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_select_buyer .card,
    #form_select_buyer_blade .card,
    #form_select_buyer_async .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .select-buyer-background{
        background-image: url(../images/bg_billing/bg_billing_lg.jpg);
        @include basic-login-background;
    }

    #form_select_buyer, #form_select_buyer_blade, #form_select_buyer_async{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_select_buyer .card,
    #form_select_buyer_blade .card,
    #form_select_buyer_async .card{
        width: 700px;
    }

}









