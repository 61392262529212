// LANDING PAGE

.landing_product_container {
    @extend .mb-4;
}

.landing_product_title {
    font-size: 1rem;
    font-weight: 600;
    @extend .mb-3;
    @extend .mt-1;
    //@extend .pl-3;
}

.landing_product_body {
    text-align: justify;
    font-size: 0.95rem;
    @extend .mb-3;
}

.landing_product_button {
    text-align: center;
}

.landing_product_price {
    @extend .mt-4;
    font-size: 1.2rem;
    text-align: center;
}

.landing_product_price_spe {
    font-size: 1.1rem;
    text-align: center;
    @extend .mb-4;
}

/* Cittadini Digitali [id 59]: nascondo il bottone di acquisto nella home landing page, viene mostrato quello della pagina statica */
.landing_product_button_59 {
    display: none;
}

// gestisco la visualizzazione della doppia descrizione e del doppio prezzo
// #list_products {

//     .landing_product_body {
//         > div:first-child {
//             .landing_product_price {display: none;}
//             .landing_product_description {display: block;}
//         }
//         > div:last-child {
//             .landing_product_price {display: block;}
//             .landing_product_description {display: none;}
//         }

//     }
// }
#list_products {

    .detail_landing_page_view {
        display:none;
    }
    .landing_product_title{
        font-size: 1.5rem;
    }
}



// Classe da applicare dagli schermi medi in su
@include media-breakpoint-up(lg) {
    #list_products .row {
        //margin-left:20px;
        //margin-right:20px;
    }

    .landing_product_body {
        //padding-left: 20px;
        //padding-right: 20px;
    }

}


// Base per lo sfondo
@mixin basic-landing-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.landing-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

.landing-background #landing_card{
    margin:0 auto !important;
}

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .landing-background {
    //     //background-image: url(../images/bg_billing/bg_billing_sm.jpg);
    //     //@include basic-landing-background;
    // }

    #landing_card {
        margin-top: ($spacer * 0) !important;
        margin-bottom: ($spacer * 0) !important;
        margin-left: ($spacer * 0) !important;
        margin-right: ($spacer * 0) !important;
    }

    #landing_card .card {}
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {

    // .landing-background {
    //     //background-image: url(../images/bg_billing/bg_billing_md.jpg);
    //     //@include basic-landing-background;
    // }

    #landing_card {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 2) !important;
        width: 730px;
        margin-left: auto;
        margin-right: auto;
    }

    #landing_card .card {}
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .landing-background {
    //     //background-image: url(../images/bg_billing/bg_billing_md.jpg);
    //     //@include basic-landing-background;
    // }

    #landing_card {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 2) !important;
        width: 900px;
        margin-left: auto;
        margin-right: auto;
    }

    #landing_card .card {}
}
