// PAGINA DI AccountDetails

// Base per lo sfondo
// @mixin basic-accountdetails-background{
//     background-position: top left;
//     position: relative;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
//   }
  .to-upper {
      text-transform: uppercase;
  }

  .accountdetails-background {
    background-image: none;
    background-color: $body-bg-card;
}

  // // Small devices (landscape phones, sotto 768px)
  @include media-breakpoint-down(sm) {
    // .accountdetails-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_AD{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
  }

  // Medium devices (tablets, tra 768px e 992px )
  @include media-breakpoint-only(md) {
    // .accountdetails-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-accountdetails-background;
    // }

    #form_AD{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_AD .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }
  }

  // Large devices (desktops, 992px and up)
  @include media-breakpoint-up(lg) {
    // .accountdetails-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-accountdetails-background;
    // }

    #form_AD{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_AD .card{
        width: $card-width-lg;
    }

  }
