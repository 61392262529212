

// PAGINA DI LOGIN

// Base per lo sfondo
@mixin select-owner-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.select-owner-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .select-owner-background{
    //     background-image: url(../images/create_pec/bg_createpec_sm.jpg);
    //     @include basic-login-background;
    // }

    #form_select_owner{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .select-owner-background{
    //     background-image: url(../images/create_pec/bg_createpec_md.jpg);
    //     @include basic-login-background;
    // }

    #form_select_owner{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_select_owner .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
        width: 520px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .select-owner-background{
    //     background-image: url(../images/create_pec/bg_createpec_lg.jpg);
    //     @include basic-login-background;
    // }

    #form_select_owner{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_select_owner .card{
        width: 700px;
    }

}









