// Body
// $body-bg: #80D3CE;
$body-bg: #f8fafc;
$body-bg-card: #80D3CE;
// $body-color: #545454;
$body-color: #35364A;


// Navigation
$top-navigation-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
$font-size-base: 0.88rem;
$line-height-base: 1.6;

$h1-font-size: 32px;

// $primary: #666666;
$primary: #35364A;

// Cittadini Digitali
$red-color: #dc1414;
$red-color-bg-hover: #d0211c;
$red-color-border-hover: #c51f1a;
$black-color: #000000;
$fontTitillium:'Titillium Web', sans-serif;

// PULSANTI
$btn-border-radius: 4px;
$btn-border-radius-lg: 4px;
$btn-border-radius-sm: 2px;

$btn-padding-x-lg: 2rem;

// INPUT
$input-color: #7e828b;
$input-bg: #ffffff;
$input-border-color: #ededed;

// CARD
$card-spacer-x: 1.75rem;
$card-width-lg:700px;

// Colors
// use https://coolors.co/ to set up a color name
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$tealHover: #4dc0b5FF;
$cyan: #6cb2eb;
$white: #ffffff;
//----
$teal:#00857E;
$verdigris:#33B8B1;
$richBlack: #111820;
$lightYellow:#FFFBDB;
$seasalt:#F8FAFC;
// --- SIULP
$blueSiulp: #1e73be;
$blueSiulp_hover: #0f5fa4;
