// Pagina pagamento avvenuto con successo

// Base per lo sfondo
// @mixin basic-payment-success-background-background {
//     background-position: top left;
//     position: relative;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
// }


.payment-success-background {
    background-image: none;
    background-color: $body-bg-card;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .payment-success-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     @include basic-payment-success-background-background;
    // }

    #payment_success_panel {
        padding-top: ($spacer * 1) !important;
        padding-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .payment-success-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-payment-success-background-background;
    // }

    #payment_success_panel {
        padding-top: ($spacer * 4) !important;
        padding-bottom: ($spacer * 4) !important;
    }

    #payment_success_panel .card {
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .payment-success-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-payment-success-background-background;
    // }

    #payment_success_panel {
        padding-top: ($spacer * 4) !important;
        padding-bottom: ($spacer * 4) !important;
    }

    #payment_success_panel .card {
        margin-left: auto;
        margin-right: auto;
        width: $card-width-lg;
    }

}
