// PAGINA CONVENZIONI GENERICHE

// Base per lo sfondo
// @mixin basic-convenzioni-generiche-background {
//     background-position: top left;
//     position: relative;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
// }

.convenzioni-generiche-background {
    background-image: none;
    background-color: $body-bg-card;
}

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .convenzioni-generiche-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     @include basic-convenzioni-generiche-background;
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_convenzioni-generiche,
    #form_verify_convenzioni-generiche,
    #form_confirm_convenzioni-generiche {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .convenzioni-generiche-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-convenzioni-generiche-background;
    // }

    #form_convenzioni-generiche,
    #form_verify_convenzioni-generiche,
    #form_confirm_convenzioni-generiche {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_convenzioni-generiche .card,
    #form_verify_convenzioni-generiche .card,
    #form_confirm_convenzioni-generiche .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_convenzioni-generiche .card,
    #form_verify_convenzioni-generiche .card,
    #form_confirm_convenzioni-generiche .card {
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .convenzioni-generiche-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-convenzioni-generiche-background;
    // }

    #form_convenzioni-generiche,
    #form_verify_convenzioni-generiche,
    #form_confirm_convenzioni-generiche {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_convenzioni-generiche .card,
    #form_verify_convenzioni-generiche .card,
    #form_confirm_convenzioni-generiche .card {
        width: $card-width-lg;
    }

}

#form_convenzioni-generiche .invalid-feedback,
#form_verify_convenzioni-generiche .invalid-feedback,
#form_confirm_convenzioni-generiche .invalid-feedback,
#convenzioni-generiche_form .valid-feedback {
    text-align: left;
}

.pdf-link {
    text-decoration: underline;
}
