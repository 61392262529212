

// PAGINA REGISTRAZIONE ACCOUNT

// Base per lo sfondo
// @mixin basic-verify-background{
//     background-position: top left;
//     position: relative;
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
// }

.verify-background {
    background-image: none;
    background-color: $body-bg-card;
}


// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .verify-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     @include basic-verify-background;
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_verify, #verify_success{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .verify-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-verify-background;
    // }

    #form_verify, #verify_success{
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_verify .card, #verify_success .card{
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_verify .card{
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .verify-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-verify-background;
    // }

    #verify_success{
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #verify_success .card{
        width: $card-width-lg;
    }

    #form_verify .card{
        width: $card-width-lg;
    }

}

#form_verify .invalid-feedback, #register_form .valid-feedback{
    text-align: left;
}

.verify-code{
    text-align: center;
    font-size: large;
    text-transform: uppercase;
}







