// PAGINA DI LOGIN


.corporate {
    background: url('../images/stemma-namirial-negativo-60x60-a20.png') no-repeat 0px -10px;
    padding-left: 50px;
}

.corporate:hover {
    text-decoration: none;
}

.login_button {
    background: $body-color!important;
}

.logged-in_button {
    background: $body-color!important;
}

.area-riservata_button {
    background: $richBlack!important;
}
.area-riservata_button>a {
    color: $verdigris!important;
}

a.view_link {
    text-decoration: underline;
}

// Base per lo sfondo
@mixin basic-login-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.login-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .login-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #form_login {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .login-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-login-background;
    // }

    #form_login {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_login .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .login-background {
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_lg.jpg);
    //     @include basic-login-background;
    // }

    #form_login {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    .login-background .card {
        width: 700px;
    }

}

// iframe reCaptcha per fix UX in caso di timeout
iframe {
    height: 100px;
}
