// PAGINA FORMULARIO CREAZIONE PEC

// Base per lo sfondo
@mixin basic-rdcform-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    .rdcform-background {
        background-image: url(../images/create_pec/bg_createpec_sm.jpg);
        @include basic-rdcform-background;
    }

    #form_rdc {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    .rdcform-background {
        background-image: url(../images/create_pec/bg_createpec_md.jpg);
        @include basic-rdcform-background;
    }

    #form_rdc {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_rdc .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_rdc .card {
        width: 600px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .rdcform-background {
        background-image: url(../images/create_pec/bg_createpec_md.jpg);
        @include basic-rdcform-background;
    }

    #form_rdc {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_rdc .card {
        width: 700px;
    }

}

#form_rdcform .invalid-feedback,
#rdcform_form .valid-feedback {
    text-align: left;
}
