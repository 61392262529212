

// Pagina riassunto ordine
#recap_owner_panel .label{
    text-align: left;
    font-weight:bold;
}

// Base per lo sfondo
@mixin basic-recap-owner-background-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.recap-owner-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .recap-owner-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    // }

    #recap_owner_panel .card{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .recap-owner-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-recap-owner-background-background;
    // }

    #recap_owner_panel .card{
        margin-top: ($spacer * 3) !important;
        margin-bottom: ($spacer * 3) !important;
        margin-left: auto;
        margin-right: auto;
        width: 650px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .recap-owner-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-recap-owner-background-background;
    // }

    #recap_owner_panel .card{
        margin-top: ($spacer * 5) !important;
        margin-bottom: ($spacer * 5) !important;
        margin-left: auto;
        margin-right: auto;
        width: 700px;
    }

}









