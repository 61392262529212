// PAGINA FORMULARIO CREAZIONE PEC

// Base per lo sfondo
@mixin basic-ownerform-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.ownerform-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .ownerform-background {
    //     background-image: url(../images/create_pec/bg_createpec_sm.jpg);
    //     @include basic-ownerform-background;
    // }

    #form_owner {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .ownerform-background {
    //     background-image: url(../images/create_pec/bg_createpec_md.jpg);
    //     @include basic-ownerform-background;
    // }

    #form_owner {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_owner .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }

    #form_owner .card {
        width: 600px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .ownerform-background {
    //     background-image: url(../images/create_pec/bg_createpec_md.jpg);
    //     @include basic-ownerform-background;
    // }

    #form_owner {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_owner .card {
        width: 700px;
    }

}

#form_ownerform .invalid-feedback,
#ownerform_form .valid-feedback {
    text-align: left;
}
