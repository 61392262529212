// Elenco caselle acquistate

#list_pecs_panel .label {
    text-align: left;
    font-weight: bold;
}

#list_pecs_panel .icon-info {
    color: #622c4f !important;
}

// Base per lo sfondo
@mixin basic-list-pecs-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


.list-pecs-background {
    background-image: none;
    background-color: $body-bg-card;
    padding: 1rem;
}

// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .list-pecs-background {
    //     background-image: url(../images/create_pec/bg_createpec_sm.jpg);
    //     background-position: top left;
    //     position: relative;
    //     -webkit-background-size: auto;
    //     -moz-background-size: auto;
    //     -o-background-size: auto;
    //     background-size: auto;
    //     background-repeat: repeat;
    // }

    #list_pecs_panel .card {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }

}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .list-pecs-background {
    //     background-image: url(../images/create_pec/bg_createpec_md.jpg);
    //     @include basic-list-pecs-background;
    // }

    #list_pecs_panel .card {
        margin-top: ($spacer * 3) !important;
        margin-bottom: ($spacer * 3) !important;
        margin-left: auto;
        margin-right: auto;
        width: 600px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .list-pecs-background {
    //     background-image: url(../images/create_pec/bg_createpec_lg.jpg);
    //     @include basic-list-pecs-background;
    // }

    #list_pecs_panel .card {
        margin-top: ($spacer * 5) !important;
        margin-bottom: ($spacer * 5) !important;
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

}
