// CONVENZIONI convenzioni PAGE
// @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700&display=swap');
:root {
    --neutral-l80: 248, 248, 247;
    --tw-bg-opacity: 1;
}
.txt-tabriz-teal{
    color: #005652;
}
.txt-base-font {
    color:#35364A;
}
.txt-black {
    color:#000;
}

.bg-gray-brooklet {
    // background-color:#E6EEEE;
    background-color: rgba(var(--neutral-l80),var(--tw-bg-opacity));
}

.alert-convenzioni {
    color: #35364A;
    background-color: #E6EEEE;
    border-color: #CCD1D1;
    padding: 1rem;
  }
  .alert-convenzioni hr {
    border-top-color: #c0ddf6;
  }
  .alert-convenzioni .alert-link {
    color: #284257;
  }

.convenzioni_product_title {
    font-size: 1rem;
    font-weight: 600;
    @extend .mb-3;
    @extend .mt-1;
    //@extend .pl-3;
}

.convenzioni_product_body {
    text-align: justify;
    font-size: 0.95rem;
    @extend .mb-3;
}

.convenzioni_product_button {
    text-align: center;
}

.convenzioni_product_price {
    @extend .mt-4;
    font-size: 1.2rem;
    text-align: center;
}

.convenzioni_product_price_spe {
    font-size: 1.1rem;
    text-align: center;
    @extend .mb-4;
}


.convenzioni-background {
    background-image: none;
    background-color: #80D3CE;
}

// Ufficio Telematico override (attivate per path /convenzioni/cittadinidigitali)
// $red-color: #dc1414;
// $red-color-bg-hover: #d0211c;
// $red-color-border-hover: #c51f1a;
// $black-color: #000000;
// $fontTitillium:'Titillium Web', sans-serif;

#convenzioni_card .card:has( .convenzioni-cittadinidigitali) {
    // border-width: 0;
    border-radius: 0;
}

.convenzioni-cittadinidigitali,
.convenzioni-utsiulp {

    font-family: $fontTitillium;
    font-size: 1rem;

    h2 { font-size: 2.5rem; font-weight: 700;}
    h3 { font-size: 2.1rem; font-weight: 600;}
    h4 { font-size: 1.75rem; font-weight: 600;}

    img {
        max-width: 100%;
    }

    .text-red {
        color:$red-color;
    }
    .text-black {
        color:$black-color;
    }
    .bg-red {
        background-color: $red-color;
    }

    .small {font-size: 90%;}

    .lead {
        font-size: 1.25rem;
    }
    .text-muted {
        color:#7f7f7f !important;
    }

    .fw-600 {
        font-weight: 600;
    }
    .fw-700 {
        font-weight: 700;
    }


    button.btn-red,
    a.btn-red {
        background-color: $red-color !important;
        border-color: $red-color !important;

        &:hover {
            background-color: $red-color-bg-hover !important;
            border-color: $red-color-border-hover !important;
        }
    }

    .card-block-cd {
        background-color: $red-color;
    }

    .convenzioni_product_price_button {
        text-align: center;
    }
}

//--- Override per siulp
.badge-blue_siulp {
    background-color: $blueSiulp;
    color:$white;
}
.convenzioni-utsiulp {

    font-family: inherit;
    font-size: .85rem;

    a:not(.btn) {
        color:$blueSiulp;
    }

    p.lead {font-size: inherit;}

    h2 { font-size: 1.9rem; font-weight: 700;}

    .text-title, .text-blue_siulp {
        color:$blueSiulp;
    }
    .bg-blue_siulp {
        background-color: $blueSiulp;
    }

    .btn-blue_siulp,
    a.btn-blue_siulp {
        background-color: $blueSiulp !important;
        border-color: $blueSiulp !important;

        &:hover {
            background-color: $blueSiulp_hover !important;
            border-color: $blueSiulp_hover !important;
        }
    }

}


// ---


// Classe da applicare dagli schermi medi in su
@include media-breakpoint-up(lg) {
    // #list_products .row {
    //     //margin-left:20px;
    //     //margin-right:20px;
    // }

    // .convenzioni_product_body {
    //     //padding-left: 20px;
    //     //padding-right: 20px;
    // }
}

// Base per lo sfondo
@mixin basic-convenzioni-background {
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin basic-convenzioni-notai {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

@mixin basic-convenzioni-geometri {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

@mixin basic-convenzioni-avvocati {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

@mixin basic-convenzioni-commercialisti {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

@mixin basic-convenzioni-areaclienti {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

// Base per lA CARD
@mixin basic-convenzioni-card {
    font-size: 0.75rem;
}

@mixin basic-promozione-card {
    font-size: 0.75rem;
    /*width: 16rem; */
    opacity: 0.9;
}

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    .convenzioni-wki-background {
        // background-image: url(../images/notaio_sm.png);
        @include basic-convenzioni-notai;
    }

    .convenzioni-geometri-background {
        // background-image: url(../images/geometri_sm.png);
        @include basic-convenzioni-geometri;
    }

    .convenzioni-avvocati-background {
        // background-image: url(../images/lawyer_sm.png);
        @include basic-convenzioni-avvocati;
    }

    .convenzioni-commercialisti-background {
        // background-image: url(../images/commercialista_sm.png);
        @include basic-convenzioni-commercialisti();
    }

    .convenzioni-areaclienti-background {
        // background-image: url(../images/pec-sliced_sm.png);
        @include basic-convenzioni-areaclienti();
    }

    .convenzioni-wki-card {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @include basic-promozione-card;
    }

    .convenzioni-geometri-card {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @include basic-promozione-card;
    }

    .convenzioni-avvocati-card {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @include basic-promozione-card;
    }

    .convenzioni-commercialisti-card {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @include basic-promozione-card;
    }

    .convenzioni-areaclienti-card {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        @include basic-promozione-card;
    }

    #convenzioni_card {
        margin-top: ($spacer * 0) !important;
        margin-bottom: ($spacer * 0) !important;
        margin-left: ($spacer * 0) !important;
        margin-right: ($spacer * 0) !important;
        @include basic-convenzioni-card;
    }

    //#convenzioni_card .card {}

}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    .convenzioni-wki-background {
        // background-image: url(../images/notaio_md.png);
        @include basic-convenzioni-notai;
    }

    .convenzioni-geometri-background {
        // background-image: url(../images/geometri_md.png);
        @include basic-convenzioni-geometri;
    }

    .convenzioni-avvocati-background {
        // background-image: url(../images/lawyer_md.png);
        @include basic-convenzioni-avvocati;
    }

    .convenzioni-commercialisti-background {
        // background-image: url(../images/commercialista_md.png);
        @include basic-convenzioni-commercialisti();
    }

    .convenzioni-areaclienti-background {
        // background-image: url(../images/pec-sliced_md.png);
        @include basic-convenzioni-areaclienti();
    }

    .convenzioni-wki-card {
        margin-bottom: 30px;
        @include basic-promozione-card;
    }

    .convenzioni-geometri-card {
        margin-bottom: 30px;
        @include basic-promozione-card;
    }

    .convenzioni-avvocati-card {
        margin-bottom: 30px;
        @include basic-promozione-card;
    }

    .convenzioni-commercialisti-card {
        margin-bottom: 30px;
        @include basic-promozione-card;
    }

    .convenzioni-areaclienti-card {
        margin-bottom: 30px;
        @include basic-promozione-card;
    }

    #convenzioni_card {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 2) !important;
        /*width: 730px;*/
        margin-left: auto;
        margin-right: auto;
        @include basic-convenzioni-card;
    }

    .convenzioni-utsiulp {
        .buy_and_desc {
            flex-direction: column-reverse;
        }
    }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .convenzioni-wki-background {
        // background-image: url(../images/notaio_lg.png);
        @include basic-convenzioni-notai;
    }

    .convenzioni-geometri-background {
        // background-image: url(../images/geometri_lg.png);
        @include basic-convenzioni-geometri;
    }

    .convenzioni-avvocati-background {
        // background-image: url(../images/lawyer_lg.png);
        @include basic-convenzioni-avvocati;
    }

    .convenzioni-commercialisti-background {
        // background-image: url(../images/commercialista_lg.png);
        @include basic-convenzioni-commercialisti();
    }

    .convenzioni-areaclienti-background {
        // background-image: url(../images/pec-sliced_lg.png);
        @include basic-convenzioni-areaclienti();
    }

    .convenzioni-wki-card {
        margin-bottom: 50px;
        @include basic-promozione-card;
    }

    .convenzioni-geometri-card {
        margin-bottom: 50px;
        @include basic-promozione-card;
    }

    .convenzioni-avvocati-card {
        margin-bottom: 50px;
        @include basic-promozione-card;
    }

    .convenzioni-commercialisti-card {
        margin-bottom: 50px;
        @include basic-promozione-card;
    }

    .convenzioni-areaclienti-card {
        margin-bottom: 50px;
        @include basic-promozione-card;
    }

    #convenzioni_card {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 2) !important;
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        @include basic-convenzioni-card;
    }

    //#convenzioni_card .card {}
}
