

// Pagina riassunto ordine

#recap_panel .field{
    text-align: left;
}

// Base per lo sfondo
@mixin basic-recap-background-background{
    background-position: top left;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    #recap_panel .card{
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

.recap-background {
    background-image: none;
    background-color: $body-bg-card;
}


// // Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    // .recap-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_sm.jpg);
    //     @include basic-recap-background-background;
    // }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    // .recap-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-recap-background-background;
    // }

    #recap_panel .card{
        margin-top: ($spacer * 3) !important;
        margin-bottom: ($spacer * 3) !important;
        margin-left: auto;
        margin-right: auto;
        width: 500px;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    // .recap-background{
    //     background-image: url(../images/bg_choose_pec/bg_choose_pec_md.jpg);
    //     @include basic-recap-background-background;
    // }

    #recap_panel .card{
        margin-top: ($spacer * 5) !important;
        margin-bottom: ($spacer * 5) !important;
        margin-left: auto;
        margin-right: auto;
        width: $card-width-lg;
    }

}









