// Pagina per l'inserimento dell'email per il reset della password

// Small devices (landscape phones, sotto 768px)
@include media-breakpoint-down(sm) {
    #form_reset_password_email_confirm {
        margin-top: ($spacer * 1) !important;
        margin-bottom: ($spacer * 1) !important;
    }
}

// Medium devices (tablets, tra 768px e 992px )
@include media-breakpoint-only(md) {
    #form_reset_password_email_confirm {
        margin-top: ($spacer * 2) !important;
        margin-bottom: ($spacer * 2) !important;
    }

    #form_reset_password_email_confirm .card {
        margin-left: ($spacer * 2) !important;
        margin-right: ($spacer * 2) !important;
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

    #form_reset_password_email_confirm {
        margin-top: ($spacer * 4) !important;
        margin-bottom: ($spacer * 4) !important;
    }

    #form_reset_password_email_confirm .card {
        width: 600px;
    }

}

